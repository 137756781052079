//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import LocaleDropdown from './LocaleDropdown'
import userData from '~/mixins/user/userData'
import formattedDate from '~/filters/formattedDate.js'
import { translateOrDefault } from '~/filters/translateOrDefault'
import CurrencyDropdown from '~/components/CurrencyDropdown'
import ContactDropdown from '~/components/ContactDropdown'
import GTM from '~/utils/classes/Gtm'
import settings from '~/mixins/settings'

export default {
  name: 'HeaderContainer',
  components: {
    NotificationDropdown: () => import('~/components/NotificationDropdown'),
    ProfileDropdown: () => import('~/components/ProfileDropdown'),
    MobileMenuModal: () => import('~/components/modal/mobile-menu-modal'),
    ContactDropdown,
    CurrencyDropdown,
    LocaleDropdown
  },
  filters: { formattedDate, translateOrDefault },
  mixins: [userData, settings],
  props: {
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      appName: process.env.appName,
      isMobileDropdown: false,
      gtm: new GTM(this.$gtm)
    }
  },
  watch: {
    $route () {
      this.isMobileDropdown = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      check: 'auth/check',
      locale: 'lang/locale',
      language: 'lang/currentLanguage',
      sysNotifications: 'dashboard/notifications',
      appTheme: 'appTheme'
    }),
    headerStyle () {
      const BASE = []
      const LIGHT = [
        'index', 'search', 'search_with_boat', 'search_with_boat_charter', 'request-offer', 'contact', 'work-with-tubber',
        'work-with-tubber-job', 'apply-work-with-tubber-job',
        'how-it-works', 'destination-location', 'about', 'insurance', 'guides',
        'let-your-boat', 'clean-ocean', 'flotilla location', 'flotilla page', 'flotillas', 'ratedCompanies',
        'reviews', 'boat-type', 'brand', 'cabincharter', 'cabincharter location', 'digital-flotilla', 'boat-view', 'sailing-holidays', 'sailing-with-skipper'
      ]
      if (LIGHT.includes(this.$route.name)) {
        return 'light'
      }
      return 'base'
    }
  },
  methods: {
    getRegisterAction () {
      if (['auth'].includes(this.$nuxt.$data.layoutName)) {
        this.$router.push({ name: 'register' })
      } else {
        if (this.locale === 'nl') {
          this.$modal.show('register-modal')
        } else {
          window.location.href = this.$router.resolve({ name: 'campaign.register', query: { utm_source: 'ZohoCampaigns', utm_medium: 'email', utm_campaign: 'singupcredits_uk' } }).href
        }
      }
    },
    getLoginAction () {
      if (['auth'].includes(this.$nuxt.$data.layoutName)) {
        this.$router.push({ name: 'login' })
      } else {
        this.$modal.show('login-modal')
      }
    },
    async logout () {
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' }).catch((e) => {})
    }
  },
  jsonld () {
    return {
      '@context': 'http://www.schema.org',
      '@type': 'Product',
      name: 'Tubber',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: this.getSettingsReviewAVG,
        ratingCount: this.getSettingsReviewsCount,
        bestRating: '5',
        worstRating: '1'
      }
    }
  }
}
