export function translateOrDefault (object, language = 'en') {
  if (object !== undefined && object !== null) {
    if (object.hasOwnProperty(language) && object[language] !== null && object[language] !== '') {
      return object[language]
    } else if (object.hasOwnProperty('en')) {
      return object.en
    } else {
      return getFirstKey(object)
    }
  }

  return ''
}

function getFirstKey (data) {
  for (const item in data) {
    if (data.hasOwnProperty(item)) {
      return data[item]
    }
  }
}
