import { render, staticRenderFns } from "./HeaderDropdown.vue?vue&type=template&id=47592e05"
import script from "./HeaderDropdown.vue?vue&type=script&lang=js"
export * from "./HeaderDropdown.vue?vue&type=script&lang=js"
import style0 from "./HeaderDropdown.vue?vue&type=style&index=0&id=47592e05&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/var/www/tubber-new/components/global/v/icon.vue').default,VDropdown: require('/var/www/tubber-new/components/global/v/dropdown.vue').default})
