import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      phoneNumbers: state => state.contact.phoneNumbers
    }),
    ...mapGetters({
      locale: 'lang/locale'
    }),
    getPhonesData () {
      const settings = {
        nl: {
          enabled: true,
          title: this.$t('the-netherlands'),
          flag: this.getFlag('nl', 'locale'),
          cFlag: this.getFlag('nl')
        },
        en: {
          enabled: true,
          title: this.$t('united-kingdom'),
          flag: this.getFlag('gb', 'locale'),
          cFlag: this.getFlag('gb')
        },
        hr: {
          enabled: true,
          title: this.$t('croatia'),
          flag: this.getFlag('hr', 'locale'),
          cFlag: this.getFlag('hr')
        },
        gr: {
          enabled: true,
          title: this.$t('greece'),
          flag: this.getFlag('gr', 'locale'),
          cFlag: this.getFlag('gr')
        },
        us: {
          enabled: true,
          title: this.$t('united-states'),
          flag: this.getFlag('us', 'locale'),
          cFlag: this.getFlag('us')
        },
        de: {
          enabled: true,
          title: this.$t('germany'),
          flag: this.getFlag('de', 'locale'),
          cFlag: this.getFlag('de')
        },
        pl: {
          enabled: true,
          title: this.$t('poland'),
          flag: this.getFlag('pl', 'locale'),
          cFlag: this.getFlag('pl')
        },
        be: {
          enabled: true,
          title: this.$t('belgium'),
          flag: this.getFlag('be', 'locale'),
          cFlag: this.getFlag('be')
        },
        ch: {
          enabled: true,
          title: this.$t('switzerland'),
          flag: this.getFlag('ch', 'locale'),
          cFlag: this.getFlag('ch')
        },
        cz: {
          enabled: true,
          title: this.$t('czechia'),
          flag: this.getFlag('cz', 'locale'),
          cFlag: this.getFlag('cz')
        },
        ca: {
          enabled: true,
          title: this.$t('canada'),
          flag: this.getFlag('ca', 'locale'),
          cFlag: this.getFlag('ca')
        },
        da: {
          enabled: true,
          title: this.$t('denmark'),
          flag: this.getFlag('dk', 'locale'),
          cFlag: this.getFlag('dk')
        }
      }
      const result = []

      for (const [key, value] of Object.entries(this.phoneNumbers)) {
        if (!Object.keys(settings).includes(key)) {
          continue
        }
        if (settings[key]?.enabled !== true) {
          continue
        }
        result.push({
          ...settings[key],
          data: value,
          countryCode: key
        })
      }
      const idx = result.findIndex(el => el.countryCode === this.locale)
      return idx === -1 ? result : [...result.slice(idx), ...result.slice(0, idx)]
    }
  }
}
