//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import HeaderDropdown from '~/components/HeaderDropdown'

export default {
  name: 'CurrencyDropdown',
  components: { HeaderDropdown },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      activeCurrencies: 'lang/currencies',
      currentCurrency: 'lang/currentCurrency'
    })
  },
  methods: {
    closeDropdown () {
      this.$refs.hDropdown.close()
    },
    setCurrentCurrency (currency) {
      this.$store.dispatch('lang/setCurrency', { currency })
      this.dropdown = false
      this.closeDropdown()
    }
  }
}
