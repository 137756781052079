//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import TransitionExpand from '~/components/transition/transition-expand'

export default {
  name: 'Dropdown',
  components: {
    TransitionExpand
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    show (e) {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    toggle () {
      if (this.isOpen) {
        this.close()
      } else {
        this.show()
      }
    },
    onClickInner () {
      this.$nextTick(() => {
        this.close()
      })
    }
  }
}
