//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import HeaderDropdown from '~/components/HeaderDropdown'
import phones from '~/mixins/phones'
import flags from '~/mixins/flags'

export default {
  name: 'ContactDropdown',
  components: { HeaderDropdown },
  mixins: [phones, flags],
  computed: {
    ...mapGetters({
      locale: 'lang/locale'
    })
  }
}
