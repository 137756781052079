export default {
  methods: {
    getFlag (item, style = '') {
      let result = item?.country_code || item
      if (item?.type) {
        switch (item.type) {
          case 'country':
            result = item?.country_code || item?.short_code
            break
          default:
            result = item?.country_code
            break
        }
      }

      if (!result) {
        return
      }
      return `/flags/${style ? `${style}/` : ''}${result?.toLowerCase()}.svg`
    }
  }
}
