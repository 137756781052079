//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import { getFlag } from '~/utils/languages'
import HeaderDropdown from '~/components/HeaderDropdown'
import { applyLocale } from '~/utils/v'

export default {
  name: 'LocaleDropdown',
  components: { HeaderDropdown },
  data () {
    return {
      dropdown: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      langUpdating: 'langUpdating',
      locale: 'lang/locale',
      activeLanguages: 'lang/locales',
      activeCurrencies: 'lang/currencies',
      currentCurrency: 'lang/currentCurrency'
    }),
    ...mapState({
      breakpoint: 'breakpoint'
    }),
    isMobile () {
      return this.breakpoint.isMobile
    }
  },
  watch: {
    $route () {
      this.dropdown = false
      this.loading = false
    }
  },
  methods: {
    closeDropdown () {
      this.$refs.hDropdown.close()
    },
    getFlag (name) {
      return getFlag(name)
    },
    setCurrentLanguage (locale) {
      if (this.loading) {
        return
      }
      this.dropdown = false
      if (this.locale !== locale) {
        this.loading = true
        this.closeDropdown()
        applyLocale(this, locale)
      }
    }
  }
}
