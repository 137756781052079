//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeaderDropdown',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$refs.dropdown.close()
    }
  }
}
